<template>
    <div class="home">
        <div class="home-title">
            <el-card shadow="always">
                <el-row>
                    <el-col :span="6" style="text-align: left">
                        <div class="greetings">您好,{{ username }}</div>
                        <div class="weather">今天是：{{ curdate }}</div>
                    </el-col>
                    <el-col :span="6" style="display: flex; justify-content: center; align-items: center">
                        <span v-if="ismerchant == 1 || ismerchant == 6 || ismerchant == 7">开店时间：{{ addtime }}</span>
                        <span v-else>开店时间：{{ merchants.openmerchant }}</span>
                    </el-col>
                </el-row>
            </el-card>
        </div>
        <div class="home-title" style="margin-top: 20px" v-if="ismerchant == 0 || ismerchant == 6 || ismerchant == 7">
            <el-card shadow="always">
                <el-row>
                    <el-col :span="1">
                        <el-button @click="allmerchant2" size="small" type="primary">商户</el-button>
                    </el-col>
                    <el-col style="display: flex; align-items: center" :span="1">
                        <span v-if="merchantname == ''"> 未选择 </span>
                        <span v-else> 已选择 </span>
                    </el-col>
                    <el-col :span="1">
                        <el-button @click="allstore2" size="small" type="primary">门店</el-button>
                    </el-col>
                    <el-col style="display: flex; align-items: center" :span="1">
                        <span v-if="storename == ''"> 未选择 </span>
                        <span v-else> 已选择 </span>
                    </el-col>
                    <el-col :span="1.7">
                        <el-button @click="allywy" size="small" type="primary">业务员</el-button>
                    </el-col>
                    <el-col style="display: flex; align-items: center" :span="1">
                        <span v-if="ywyname == ''"> 未选择 </span>
                        <span v-else> 已选择 </span>
                    </el-col>
                    <el-col :span="1">
                        <el-button @click="allarea2" size="small" type="primary">区域</el-button>
                    </el-col>
                    <el-col style="display: flex; align-items: center" :span="1">
                        <span v-if="areaname == ''"> 未选择 </span>
                        <span v-else> 已选择 </span>
                    </el-col>
                    <!-- <el-col :span="1">
                        <el-button @click="repurchase" size="small" type="primary">复购</el-button>
                    </el-col>
                    <el-col style="display: flex; align-items: center" :span="1">
                        <span v-if="repurchases == ''"> 未选择 </span>
                        <span v-else> 已选择 </span>
                    </el-col> -->
                    <el-col :span="1.5">
                        <el-button @click="price" size="small" type="primary">价格区间</el-button>
                    </el-col>
                    <el-col style="display: flex; align-items: center" :span="1">
                        <span v-if="pricess.length == 0"> 未选择 </span>
                        <span v-else> 已选择 </span>
                    </el-col>
                    <el-col :span="1">
                        <el-button @click="allbrand" size="small" type="primary">品牌</el-button>
                    </el-col>
                    <el-col style="display: flex; align-items: center" :span="1">
                        <span v-if="brandss.length == 0"> 未选择 </span>
                        <span v-else> 已选择 </span>
                    </el-col>
                    <el-col :span="1.5">
                        <el-button @click="neworold" size="small" type="primary">二手/全新</el-button>
                    </el-col>
                    <el-col style="display: flex; align-items: center" :span="1">
                        <span v-if="newold == ''"> 未选择 </span>
                        <span v-else> 已选择 </span>
                    </el-col>
                    <el-col :span="1.5">
                        <el-button @click="lease" size="small" type="primary">租赁方案</el-button>
                    </el-col>
                    <el-col style="display: flex; align-items: center" :span="1">
                        <span v-if="leases == ''"> 未选择 </span>
                        <span v-else> 已选择 </span>
                    </el-col>
                </el-row>
            </el-card>
        </div>
        <el-tabs v-model="activeName" v-if="ismerchant == 0 || ismerchant == 6 || ismerchant == 7" v-loading="loading"
                 element-loading-text="处理中..." style="margin-top: 20px" @tab-click="handleClick">
            <el-tab-pane label="整体数据" name="first">
                下单时间：<el-date-picker v-model="date" type="daterange" range-separator="到" start-placeholder="开始时间"
                                end-placeholder="结束时间" size="small" />
                <el-button type="primary" @click="search" style="margin-left: 20px" size="small">搜索</el-button>
                <el-button type="primary" @click="exportfile" size="small">密码导出</el-button>
                <el-button type="primary" @click="exportall2" size="small">无密导出</el-button>
                <span v-if="downloadProgress > 0">下载进度: {{ downloadProgress }}%</span>
                <el-button type="primary" size="small" @click="getdata(2)">刷新</el-button>
                <el-text class="mx-1" style="color: #409eff" size="small">上次更新时间：{{ merchants.updateTime }}</el-text>
                <div class="home-card m120">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-document"></i> 已发货订单总数
                                    </div>
                                    <div class="card-info-no">{{ merchants.totalorder }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-coin"></i> 租赁中订单总数
                                    </div>
                                    <div class="card-info-no">{{ merchants.renting }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-box"></i> 已买断/已完结订单总数
                                    </div>
                                    <div class="card-info-no">{{ merchants.buyout }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-files"></i>总机价(含点)
                                    </div>
                                    <div class="card-info-no">{{ merchants.cost }}</div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px" :gutter="20">
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-document"></i> 签约价值总和
                                    </div>
                                    <div class="card-info-no">{{ merchants.signpricetotal }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-box"></i> 已收租金
                                    </div>
                                    <div class="card-info-no">{{ merchants.fund }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-coin"></i> 回款资金
                                    </div>
                                    <div class="card-info-no">{{ merchants.huikuan }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-coin"></i> 应收未收租金
                                    </div>
                                    <div class="card-info-no">{{ merchants.yingshou }}</div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px" :gutter="20">
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-box"></i> 逾期订单数
                                    </div>
                                    <div class="card-info-no">{{ merchants.overdue }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-box"></i> 逾期率(逾期订单/已发货订单)
                                    </div>
                                    <div class="card-info-no">{{ merchants.yql }}%</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-coin"></i> 逾期金额
                                    </div>
                                    <div class="card-info-no">{{ merchants.yuqi }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-files"></i> 逾期金额率<span style="font-size: 17px">(逾期金额/签约价值总和)</span>
                                    </div>
                                    <div class="card-info-no">{{ merchants.overduemoney }}%</div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px" :gutter="20">
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-coin"></i>首逾订单数(第2期租金扣款订单数量)
                                    </div>
                                    <div class="card-info-no">{{ merchants.twofail }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-coin"></i>首逾率(第二期租金扣款失败的订单/总订单数)
                                    </div>
                                    <div class="card-info-no">{{ merchants.twfai }}%</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-coin"></i>首逾金额(第2期租金扣款订单的应收未收租金)
                                    </div>
                                    <div class="card-info-no">{{ merchants.failmoney }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-coin"></i>金额首逾率(逻辑:首逾金额/签约价值)
                                    </div>
                                    <div class="card-info-no">{{ merchants.moneyfail }}%</div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px" :gutter="20">
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-coin"></i>毛利(签约价值+首付-投入设备总机价-优惠金)
                                    </div>
                                    <div class="card-info-no">{{ merchants.maoli }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-document"></i>毛利率(毛利/签约价值)
                                    </div>
                                    <div class="card-info-no">{{ merchants.maolil }}%</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-coin"></i>押金
                                    </div>
                                    <div class="card-info-no">{{ merchants.yajin }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-coin"></i>首付金额
                                    </div>
                                    <div class="card-info-no">{{ merchants.shoufu }}</div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px" :gutter="20">
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-coin"></i>锁机费
                                    </div>
                                    <div class="card-info-no">{{ merchants.suoji }}</div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="6">
                            <el-card class="is-always-shadow2">
                                <div class="card-info">
                                    <div class="card-info-title">
                                        <i class="el-icon-coin"></i>审核费
                                    </div>
                                    <div class="card-info-no">{{ merchants.shenhe }}</div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </el-tab-pane>
            <el-tab-pane label="日报" name="twelve">
                <el-tabs type="border-card" v-model="dayreport" @tab-click="dayreportclick">
                    <el-tab-pane label="商户" name="商户">
                        <el-row :gutter="20">
                            <el-col :span="5" style="text-align: center;">
                                <h3>近12个月逾期率超过15%</h3>
                                <el-table :data="overduemer" border style="width: 100%">
                                    <el-table-column prop="merchant" label="商户" align="center" />
                                    <el-table-column prop="div" label="逾期率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="5" style="text-align: center;">
                                <h3>近12个月金额坏账率超过6%</h3>
                                <el-table :data="badrate" border style="width: 100%">
                                    <el-table-column prop="merchant" label="商户" align="center" />
                                    <el-table-column prop="div" label="坏账率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="4" style="text-align: center;">
                                <h3>近3个月有首逾超过5%</h3>
                                <el-table :data="firstrate" border style="width: 100%">
                                    <el-table-column prop="merchant" label="商户" align="center" />
                                    <el-table-column prop="div" label="首逾率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="6" style="text-align: center;">
                                <div class="grid-content ep-bg-purple" />
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="门店" name="门店">
                        <el-check-tag :checked="item.checked" style="margin-right: 10px;" v-for="(item,index) in historys" :key="index" @change="historytotal(item)" :type="item.checked ? 'danger' : 'info'">
                            {{ item.name }}
                        </el-check-tag>
                        <el-row :gutter="20">
                            <el-col :span="6" style="text-align: center;">
                                <h3>近12个月逾期率超过15%</h3>
                                <el-table :data="storeOverdueRate" border style="width: 100%">
                                    <el-table-column prop="storename.storename" label="门店" align="center" />
                                    <el-table-column label="逾期率" align="center">
                                        <template #default="scope">
                                            <span style="color: red;" v-if="parseFloat(scope.row.div) >= 20">
                                                {{ scope.row.div }}
                                            </span>
                                            <span style="color:orange" v-else-if="parseFloat(scope.row.div) >= 15">
                                                {{ scope.row.div }}
                                            </span>
                                            <span v-else>
                                                {{ scope.row.div }}
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="storename.storetype" label="店铺类型" :formatter="formatterstoretype" align="center" />
                                    <el-table-column prop="storename.addtime" label="合作时长/月" :formatter="formatTime" align="center" />
                                    <el-table-column prop="storename.total" label="历史单量/单" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="6" style="text-align: center;">
                                <h3>近12个月金额坏账率超过6%</h3>
                                <el-table :data="storeBadRate" border style="width: 100%">
                                    <el-table-column prop="storename.storename" label="门店" align="center" />
                                    <el-table-column label="坏账率" align="center">
                                        <template #default="scope">
                                            <span style="color: red;" v-if="parseFloat(scope.row.div) >= 8">
                                                {{ scope.row.div }}
                                            </span>
                                            <span style="color:orange" v-else-if="parseFloat(scope.row.div) >= 6">
                                                {{ scope.row.div }}
                                            </span>
                                            <span v-else>
                                                {{ scope.row.div }}
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="storename.storetype" label="店铺类型" :formatter="formatterstoretype" align="center" />
                                    <el-table-column prop="storename.addtime" label="合作时长/月" :formatter="formatTime" align="center" />
                                    <el-table-column prop="storename.total" label="历史单量/单" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="6" style="text-align: center;">
                                <h3>近3个月有首逾超过5%</h3>
                                <el-table :data="storeFirstRate" border style="width: 100%">
                                    <el-table-column prop="storename.storename" label="门店" align="center" />
                                    <el-table-column prop="div" label="首逾率" align="center" />
                                    <el-table-column prop="storename.storetype" label="店铺类型" :formatter="formatterstoretype" align="center" />
                                    <el-table-column prop="storename.addtime" label="合作时长/月" :formatter="formatTime" align="center" />
                                    <el-table-column prop="storename.total" label="历史单量/单" align="center" />
                                </el-table>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="业务员" name="业务员">
                        <el-check-tag :checked="checked1" type="success" @change="onChange1">
                            在职
                        </el-check-tag>
                        <el-check-tag :checked="checked5" style="margin-left: 20px;" type="danger" @change="onChange5">
                            离职
                        </el-check-tag>
                        <el-row :gutter="20" style="font-weight:bold">
                            <el-col :span="5" style="text-align: center;">
                                <h3>近12个月逾期率超过15%</h3>
                                <el-table :data="ywyOverdueRate" border style="width: 100%">
                                    <el-table-column prop="ywyname.ywyname" label="业务员" align="center" />
                                    <el-table-column label="逾期率" align="center">
                                        <template #default="scope">
                                            <span style="color: red;" v-if="parseFloat(scope.row.div) >= 20">
                                                {{ scope.row.div }}
                                            </span>
                                            <span style="color:orange" v-else-if="parseFloat(scope.row.div) >= 15">
                                                {{ scope.row.div }}
                                            </span>
                                            <span v-else>
                                                {{ scope.row.div }}
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ywyname.addtime" :formatter="formatTime" label="工龄(月)" align="center" />
                                    <el-table-column prop="ywyname.res" label="离职/在职" :formatter="formatStatus" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="5" style="text-align: center;">
                                <h3>近12个月金额坏账率超过6%</h3>
                                <el-table :data="ywyBadRate" border style="width: 100%">
                                    <el-table-column prop="ywyname.ywyname" label="业务员" align="center" />
                                    <el-table-column label="坏账率" align="center">
                                        <template #default="scope">
                                            <span style="color: red;" v-if="parseFloat(scope.row.div) >= 8">
                                                {{ scope.row.div }}
                                            </span>
                                            <span style="color:orange" v-else-if="parseFloat(scope.row.div) >= 6">
                                                {{ scope.row.div }}
                                            </span>
                                            <span v-else>
                                                {{ scope.row.div }}
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ywyname.addtime" :formatter="formatTime" label="工龄(月)" align="center" />
                                    <el-table-column prop="ywyname.res" label="离职/在职" :formatter="formatStatus" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="5" style="text-align: center;">
                                <h3>近3个月有首逾超过5%</h3>
                                <el-table :data="ywyFirstRate" border style="width: 100%">
                                    <el-table-column prop="ywyname.ywyname" label="业务员" align="center" />
                                    <el-table-column prop="div" label="首逾率" align="center" />
                                    <el-table-column prop="ywyname.addtime" :formatter="formatTime" label="工龄(月)" align="center" />
                                    <el-table-column prop="ywyname.res" label="离职/在职" :formatter="formatStatus" align="center" />
                                </el-table>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="区域" name="区域">
                        <el-row :gutter="20">
                            <el-col :span="5" style="text-align: center;">
                                <h3>近12个月逾期率超过15%</h3>
                                <el-table :data="areaOverdueRate" border style="width: 100%">
                                    <el-table-column prop="city" label="区域" align="center" />
                                    <el-table-column prop="div" label="逾期率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="5" style="text-align: center;">
                                <h3>近12个月金额坏账率超过6%</h3>
                                <el-table :data="areaBadRate" border style="width: 100%">
                                    <el-table-column prop="city" label="区域" align="center" />
                                    <el-table-column prop="div" label="坏账率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="4" style="text-align: center;">
                                <h3>近3个月有首逾超过5%</h3>
                                <el-table :data="areaFirstRate" border style="width: 100%">
                                    <el-table-column prop="city" label="区域" align="center" />
                                    <el-table-column prop="div" label="首逾率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="6" style="text-align: center;">
                                <div class="grid-content ep-bg-purple" />
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!-- <el-tab-pane label="价格区间" name="价格区间">价格区间</el-tab-pane> -->
                    <el-tab-pane label="品牌" name="品牌">
                        <el-row :gutter="20">
                            <el-col :span="5" style="text-align: center;">
                                <h3>近12个月逾期率超过15%</h3>
                                <el-table :data="brandOverdueRate" border style="width: 100%">
                                    <el-table-column prop="brand" label="品牌" align="center" />
                                    <el-table-column prop="div" label="逾期率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="5" style="text-align: center;">
                                <h3>近12个月金额坏账率超过6%</h3>
                                <el-table :data="brandBadRate" border style="width: 100%">
                                    <el-table-column prop="brand" label="品牌" align="center" />
                                    <el-table-column prop="div" label="坏账率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="4" style="text-align: center;">
                                <h3>近3个月有首逾超过5%</h3>
                                <el-table :data="brandFirstRate" border style="width: 100%">
                                    <el-table-column prop="brand" label="品牌" align="center" />
                                    <el-table-column prop="div" label="首逾率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="6" style="text-align: center;">
                                <div class="grid-content ep-bg-purple" />
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="二手/全新" name="二手/全新">
                        <el-row :gutter="20">
                            <el-col :span="5" style="text-align: center;">
                                <h3>近12个月逾期率超过15%</h3>
                                <el-table :data="newoldOverdueRate" border style="width: 100%">
                                    <el-table-column prop="quality" label="二手/全新" align="center" />
                                    <el-table-column prop="div" label="逾期率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="5" style="text-align: center;">
                                <h3>近12个月金额坏账率超过6%</h3>
                                <el-table :data="newoldBadRate" border style="width: 100%">
                                    <el-table-column prop="quality" label="二手/全新" align="center" />
                                    <el-table-column prop="div" label="坏账率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="4" style="text-align: center;">
                                <h3>近3个月有首逾超过5%</h3>
                                <el-table :data="newoldFirstRate" border style="width: 100%">
                                    <el-table-column prop="quality" label="二手/全新" align="center" />
                                    <el-table-column prop="div" label="首逾率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="6" style="text-align: center;">
                                <div class="grid-content ep-bg-purple" />
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="租赁方案" name="租赁方案">
                        <el-row :gutter="20">
                            <el-col :span="5" style="text-align: center;">
                                <h3>近12个月逾期率超过15%</h3>
                                <el-table :data="leaseOverdueRate" border style="width: 100%">
                                    <el-table-column prop="ispurchase" label="租赁方案" align="center" />
                                    <el-table-column prop="div" label="逾期率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="5" style="text-align: center;">
                                <h3>近12个月金额坏账率超过6%</h3>
                                <el-table :data="leaseBadRate" border style="width: 100%">
                                    <el-table-column prop="ispurchase" label="租赁方案" align="center" />
                                    <el-table-column prop="div" label="坏账率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="4" style="text-align: center;">
                                <h3>近3个月有首逾超过5%</h3>
                                <el-table :data="leaseFirstRate" border style="width: 100%">
                                    <el-table-column prop="ispurchase" label="租赁方案" align="center" />
                                    <el-table-column prop="div" label="首逾率" align="center" />
                                </el-table>
                            </el-col>
                            <el-col :span="6" style="text-align: center;">
                                <div class="grid-content ep-bg-purple" />
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="月份数据" name="second">
                <el-button size="small" type="primary" @click="getMonthData(2)">刷新</el-button><span v-if="mers != ''">店铺:({{
                    mers }})</span>上次更新时间：{{ monthDatas.updateTime }}
                <el-table :data="monthDatas" class="monthclass" style="width: 100%; margin-top: 20px" min-height="700">
                    <el-table-column prop="jinglirun" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                预-净利润
                                <el-tooltip class="box-item" effect="light" content="签约价值总和*0.9+逾期订单押金+首付金额-总机价-M1以上逾期金额-优惠金-锁机费" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="huibaolv" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                预-回报率（年化）
                                <el-tooltip class="box-item" effect="light" content="预-净利润/（签约价值总和/3/1.3）*100" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="shilirun" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                实-净利润
                                <el-tooltip class="box-item" effect="light" content="已收租金*0.9+逾期订单押金+首付金额-总机价-锁机费" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="shihuilv" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                实-回报率（年化）
                                <el-tooltip class="box-item" effect="light" content="实-净利润/（签约价值总和/3/1.3）*100" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="month" label="月份" />
                    <el-table-column prop="totalorder" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                已发货订单总数
                                <el-tooltip class="box-item" effect="light" content="租赁中订单+已买断/已完结订单" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="renting" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                租赁中订单总数
                                <el-tooltip class="box-item" effect="light" content="待归还+还款逾期中" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="buyout" label="已买断/已完结订单总数" sortable />
                    <el-table-column prop="overduerate" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                逾期率/%
                                <el-tooltip class="box-item" effect="light" content="逾期订单/已发货订单" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="badMoney" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                金额坏账率
                                <el-tooltip class="box-item" effect="light" content="M1以上逾期金额/签约价值总和" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fst" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                首逾率/%
                                <el-tooltip class="box-item" effect="light" content="首逾订单/已发货订单" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cost" label="总机价(含点)" sortable />
                    <el-table-column prop="signpricetotal" label="签约价值总和" sortable />
                    <el-table-column prop="fund" label="已收租金" sortable />
                    <el-table-column prop="huikuan" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                回款资金
                                <el-tooltip class="box-item" effect="light" content="已收租金+租赁中订单的押金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="yingshou" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                应收未收租金
                                <el-tooltip class="box-item" effect="light" content="签约价值总和-已收租金-优惠金额-在租订单押金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="overdue" label="逾期订单数" sortable />
                    <el-table-column prop="overdueM0" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                M0订单数
                                <el-tooltip class="box-item" effect="light" content="30天以内逾期的订单" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="overdueM1" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                M1以上订单数
                                <el-tooltip class="box-item" effect="light" content="30天以上逾期的订单" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="yuqi" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                逾期金额
                                <el-tooltip class="box-item" effect="light" content="逾期订单的签约价值-已收租金-押金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mm0" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                M0逾期金额
                                <el-tooltip class="box-item" effect="light" content="M0订单的签约价值-已收租金-押金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mm1" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                M1以上逾期金额
                                <el-tooltip class="box-item" effect="light" content="M1订单的（签约价值-已收租金-M1订单的押金）" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="yuqirate" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                逾期金额率/%
                                <el-tooltip class="box-item" effect="light" content="逾期金额/签约价值总和" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="first" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                首逾订单数量
                                <el-tooltip class="box-item" effect="light" content="第2期租金扣款失败订单" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="firstmoney" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                首逾金额
                                <el-tooltip class="box-item" effect="light" content="首逾订单的签约价值-已收租金-押金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="firstmoneyrate" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                首逾金额率/%
                                <el-tooltip class="box-item" effect="light" content="首逾金额/签约价值总和" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fmrate" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                逾期/首逾金额率
                                <el-tooltip class="box-item" effect="light" content="逾期金额/首逾金额" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="maoli" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                毛利
                                <el-tooltip class="box-item" effect="light" content="签约价值总和+首付金额-总机价-优惠金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="maolirate" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                毛利率/%
                                <el-tooltip class="box-item" effect="light" content="毛利/签约价值总和" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deposit" label="在租订单押金" sortable />
                    <el-table-column prop="deposit2" label="逾期订单押金" sortable />
                    <el-table-column prop="favorable" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                优惠金
                                <el-tooltip class="box-item" effect="light" content="已买断已完结订单的签约价值-已收租金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="favorablerate" sortable>
                        <template #header="{ }">
                            <div class="custom-header">
                                优惠金率
                                <el-tooltip class="box-item" effect="light" content="优惠金/（签约价值总和+首付金额-总机价）" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="firstpay" label="首付金额" sortable />
                    <el-table-column prop="suoji" label="锁机费" sortable />
                    <el-table-column prop="shenhe" label="审核费" sortable />
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="门店数据" name="three">
                店铺名称：<el-input v-model="keyword" size="small" style="width: 180px" placeholder="请输入店铺名称" clearable />
                签约日期：<el-date-picker v-model="date" type="daterange" range-separator="到" start-placeholder="开始时间"
                                end-placeholder="结束时间" size="small" />
                <el-button type="primary" @click="search" style="margin-left: 20px" size="small">搜索</el-button>
                <el-button type="primary" @click="getstores(2)" size="small">刷新</el-button>上次更新时间：{{ tableData.updateTime }}
                <el-table :data="tableData" style="width: 100%; margin-top: 20px">
                    <el-table-column type="index" />
                    <el-table-column prop="storename" label="店铺名称" width="200px" />
                    <el-table-column prop="renting" label="在租订单/个" sortable />
                    <el-table-column prop="overdueing" label="逾期订单/个" sortable />
                    <el-table-column prop="overdue" label="逾期率/%" sortable />
                    <el-table-column prop="storetype" label="店铺类型" />
                    <el-table-column prop="addtime" label="签约日期" width="200px" sortable />
                    <el-table-column prop="cooperation" label="合作时长/天" sortable />
                    <el-table-column prop="remark" label="备注" />
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="业务员数据" name="four">
                入职时间：<el-date-picker v-model="entry_date" type="daterange" range-separator="到" start-placeholder="开始时间"
                                end-placeholder="结束时间" size="small" />
                <el-button type="primary" @click="search" style="margin-left: 20px" size="small">搜索</el-button>
                <el-button @click="getywy(1)" type="primary" size="small">
                    刷新</el-button>
                上次更新时间：{{ tableywy.updateTime }}
                <el-table :data="tableywy" style="width: 100%; margin-top: 20px">
                    <el-table-column type="index" />
                    <el-table-column prop="ywyname" label="业务员姓名" />
                    <el-table-column prop="addtime" label="入职时间" sortable />
                    <el-table-column prop="Merchant" label="所属代理商" />
                    <el-table-column prop="total" label="发货订单总数" sortable />
                    <el-table-column prop="renting" label="租赁中订单" sortable />
                    <el-table-column prop="buyout" label="买断/已完成订单" sortable />
                    <el-table-column prop="cost" label="设备总成本" sortable />
                    <el-table-column prop="signpricetotal" label="签约价总和" sortable />
                    <el-table-column prop="return" sortable>
                        <template #header="{ }">
                            回款率
                            <div class="custom-header">
                                <el-tooltip class="box-item" effect="light" content="已收租金/总机价" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fund" label="已回收客户资金" sortable />
                    <el-table-column prop="first" label="首逾率/%" sortable />
                    <el-table-column prop="overdue" label="逾期订单数" sortable />
                    <el-table-column prop="yingshou" label="应收未收租金" sortable />
                    <el-table-column prop="yuqi" label="逾期金额" sortable />
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="每日数据" name="five">
                时间：<el-date-picker v-model="every_day" type="daterange" range-separator="到" start-placeholder="开始时间"
                                end-placeholder="结束时间" size="small" />
                <el-button type="primary" @click="search" style="margin-left: 20px" size="small">搜索</el-button>
                <el-button @click="everydaydata(1)" type="primary" size="small">
                    刷新</el-button>
                上次更新时间：{{ everyday.updateTime }}
                <el-table :data="everyday" height="700" style="width: 100%; margin-top: 20px">
                    <el-table-column prop="time" label="日期" sortable />
                    <el-table-column prop="total" label="订单数" sortable />
                    <el-table-column prop="buyoutprice" label="签约价" sortable />
                    <el-table-column prop="margin" label="总机价" sortable />
                    <el-table-column prop="average" sortable>
                        <template #header="{ }">
                            平均机价
                            <div class="custom-header">
                                <el-tooltip class="box-item" effect="light" content="总机价/订单数" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="profit" sortable>
                        <template #header="{ }">
                            毛利
                            <div class="custom-header">
                                <el-tooltip class="box-item" effect="light" content="签约价值总和-总机价" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="shoufu" label="首付" sortable />
                    <el-table-column prop="shoufubili" sortable>
                        <template #header="{ }">
                            首付比例/%
                            <div class="custom-header">
                                <el-tooltip class="box-item" effect="light" content="首付金额/总机价" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sum" label="今日回款" sortable />
                    <el-table-column prop="dfund" sortable>
                        <template #header="{ }">
                            今日垫付
                            <div class="custom-header">
                                <el-tooltip class="box-item" effect="light" content="总机价-回款资金" placement="top">
                                    <span class="tip">?</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background :page-sizes="[200, 500]" layout="total, sizes,  prev, pager, next, jumper"
                               v-model:page-size="everypageSize" @size-change="everySizeChange" @current-change="everyCurrentChange"
                               :total="everytotal" />
            </el-tab-pane>
            <el-tab-pane label="逾期数据" name="six">
                <el-button @click="overduedatas(1)" type="primary" size="small">
                    刷新</el-button>
                上次更新时间：{{ overduedata.updateTime }}
                <el-table :data="overduedata" :default-sort="{ prop: 'AddDTime' }" style="width: 100%; margin-top: 20px"
                          height="700px">
                    <el-table-column type="index" />
                    <el-table-column prop="storename" label="门店" />
                    <el-table-column prop="ywyname" label="业务员" />
                    <el-table-column prop="Merchant" label="商户" />
                    <el-table-column prop="agentname" label="区域团队" />
                    <el-table-column prop="level" label="等级" />
                    <el-table-column prop="AddDTime" label="下单日期" sortable />
                    <el-table-column prop="overdue" label="逾期天数" sortable />
                    <el-table-column prop="buyoutprice" label="签约价值" sortable />
                    <el-table-column prop="paidRent" label="已交租" sortable />
                    <el-table-column prop="deposit" label="押金" sortable />
                    <el-table-column prop="OrderUID" label="订单编号" />
                    <el-table-column prop="Status" label="订单状态" />
                    <el-table-column prop="overdueMoney" label="逾期金额" sortable />
                    <el-table-column prop="yueRent" label="月租金" sortable />
                    <el-table-column prop="sex" label="性别" />
                    <el-table-column prop="age" label="年龄" sortable />
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="办单检测" name="seven">
                <el-tag type="danger" @click="Detection" style="cursor: pointer">开始检测</el-tag>
                <el-table v-if="isdetection" :data="checkResult" style="width: 30%; margin-top: 20px" size="small">
                    <el-table-column prop="time" label="检测日期" align="center" />
                    <el-table-column label="检测结果" align="center">
                        <el-table-column prop="normal" label="正常" align="center">
                            <template #default="scope">
                                <span style="color: #67c23a">{{ scope.row.normal }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="abnormal" label="异常" align="center">
                            <template #default="scope">
                                <span style="color: red">{{ scope.row.abnormal }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <el-table v-if="isdetection" :data="checkInfo" style="margin-top: 20px" height="600" size="small">
                    <el-table-column label="检测结果" align="center">
                        <el-table-column fixed type="index" label="序号" />
                        <el-table-column prop="status" label="检测状态" align="center">
                            <template #default="scope">
                                <el-tag size="small" :type="scope.row.status == '正常' ? 'success' : 'danger'">
                                    {{ scope.row.status }}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="LicenseImg" label="身份证" align="center" />
                        <el-table-column prop="is_fill_pay" label="首付" align="center" />
                        <el-table-column prop="rent" label="已收租金" align="center" />
                        <el-table-column prop="AddDTime" label="下单时间" align="center" sortable />
                        <el-table-column prop="ywyname" label="业务员" align="center" />
                        <el-table-column prop="agentname" label="区域团队" align="center" />
                        <el-table-column prop="Status" label="订单状态" align="center">
                            <template #default="scope">
                                <span v-if="scope.row.Status == 2"> 待发货 </span>
                                <span v-if="scope.row.Status == 4"> 待审核 </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="OrderUID" label="订单编号" align="center" />
                        <el-table-column prop="Receiver" label="客户姓名" align="center" />
                    </el-table-column>
                </el-table>
                <el-empty v-if="!isdetection" description="暂无数据" />
            </el-tab-pane>
            <el-tab-pane label="区域数据" name="eight">
                <el-button @click="getCityData(1)" type="primary" size="small">
                    刷新</el-button>
                上次更新时间：{{ cityDatas.updateTime }}
                <el-table :data="cityDatas" style="width: 100%; margin-top: 20px">
                    <el-table-column prop="city" label="所属区域" />
                    <el-table-column prop="total" label="已发货订单总数" sortable />
                    <el-table-column prop="rent" label="租赁中订单数总数" sortable />
                    <el-table-column prop="buyout" label="已买断/已完结订单总数" sortable />
                    <el-table-column prop="cost" label="总机价(含点)" sortable />
                    <el-table-column prop="signprice" label="签约价值总和" sortable />
                    <el-table-column prop="overdueMoney" label="逾期金额" sortable>
                        <template #default="scope">
                            {{ scope.row.overdueMoney - scope.row.deposit }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="fund" label="已回收资金" sortable />
                    <el-table-column prop="money" label="回款资金" sortable />
                    <el-table-column prop="first" label="首逾率" sortable />
                    <el-table-column prop="overdue" label="逾期订单数" sortable />
                    <el-table-column prop="amount" label="锁机费" sortable />
                    <el-table-column prop="ying" label="应收未收租金" sortable />
                </el-table>
                <el-pagination background :page-sizes="[200, 500]" layout="total, sizes,  prev, pager, next, jumper"
                               v-model:page-size="everypageSize" @size-change="everySizeChange" @current-change="everyCurrentChange"
                               :total="everytotal" />
            </el-tab-pane>
            <el-tab-pane label="今日业绩" name="nine">
                <div style="display: flex">
                    <el-divider style="flex:1">日榜</el-divider>
                    <el-divider style="flex:1">月榜</el-divider>
                </div>
                <div style="display: flex;">
                    <el-table :data="dayData">
                        <el-table-column prop="city" label="区域">
                            <template #default="scope">
                                {{ scope.row.city == null ? "暂无" : scope.row.city }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="ywyname" label="姓名">
                            <template #default="scope">
                                {{ scope.row.ywyname == null ? "暂无" : scope.row.ywyname }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="num" label="订单数量" />
                        <el-table-column prop="money" label="金额" />
                    </el-table>
                    <el-divider direction="vertical" border-style="dashed" style="height: auto;" />
                    <el-table :data="monthData">
                        <el-table-column prop="city" label="区域">
                            <template #default="scope">
                                {{ scope.row.city == null ? "暂无" : scope.row.city }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="ywyname" label="姓名">
                            <template #default="scope">
                                {{ scope.row.ywyname == null ? "暂无" : scope.row.ywyname }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="num" label="订单数量" />
                        <el-table-column prop="money" label="金额" />
                    </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane label="资金投入概况" name="ten">
                <el-card style="max-width: 480px">
                    <p>您目前投入本金为{{ touru }}元</p>
                    <p>转化为月度占用资金，约为{{ leiji }}元</p>
                    <p>转化为年度占用资金，约为{{ yuedu }}元</p>
                </el-card>
                <el-table :data="Investment['res']" border style="margin-top: 20px">
                    <el-table-column prop="month" label="月份" align="center" />
                    <el-table-column prop="principal" label="累计本金" align="center" />
                    <el-table-column prop="Investment" label="充值" align="center" />
                    <el-table-column prop="CashOut" label="提现" align="center" />
                    <el-table-column prop="occupy" label="月度占用资金" align="center" />
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="款项核对" name="eleven">
                <el-tag type="danger" @click="Detection2" style="cursor: pointer">开始检测</el-tag>
                <el-table v-if="isdetection2" :data="checkResult2" style="width: 30%; margin-top: 20px" size="small">
                    <el-table-column prop="time" label="检测日期" align="center" />
                    <el-table-column label="检测结果" align="center">
                        <el-table-column prop="normal" label="正常" align="center">
                            <template #default="scope">
                                <span style="color: #67c23a">{{ scope.row.normal }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="abnormal" label="异常" align="center">
                            <template #default="scope">
                                <span style="color: red">{{ scope.row.abnormal }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <el-table v-if="isdetection2" :data="checkInfo2" style="margin-top: 20px" height="600" size="small">
                    <el-table-column label="检测结果" align="center">
                        <el-table-column prop="checkPrice" label="价格核准" align="center">
                            <template #default="scope">
                                <el-tag size="small" :type="scope.row.checkPrice == '正常' ? 'success' : 'danger'">
                                    {{ scope.row.checkPrice }}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="AddDTime" label="下单时间" align="center" sortable />
                        <el-table-column prop="ywyname" label="业务员" align="center" />
                        <el-table-column prop="agentname" label="区域团队" align="center" />
                        <el-table-column prop="Status" label="订单状态" align="center">
                            <template #default="scope">
                                <span v-if="scope.row.Status == 2"> 待发货 </span>
                                <span v-if="scope.row.Status == 4"> 待审核 </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="OrderUID" label="订单编号" align="center" />
                        <el-table-column prop="Receiver" label="客户姓名" align="center" />
                        <el-table-column prop="buyoutprice" label="签约价" align="center" />
                        <el-table-column prop="zsb" label="租售比" align="center" />
                        <el-table-column prop="zsb1" label="签约价/打款价" align="center" />
                        <el-table-column prop="zsb2" label="签约价/机价" align="center" />
                    </el-table-column>
                </el-table>
                <el-empty v-if="!isdetection2" description="暂无数据" />
            </el-tab-pane>
        </el-tabs>
    </div>
    <el-dialog v-model="merchant" title="选择商户" width="16%">
        <el-select v-model="vv" multiple filterable clearable placeholder="选择商户">
            <el-option v-for="item in allmerchant" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="merchant = false">取消</el-button>
                <el-button type="primary" @click="savemerchant"> 确认 </el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="store" title="选择门店" width="16%">
        <el-select v-model="ss" multiple filterable clearable placeholder="选择门店">
            <el-option v-for="item in allstore" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="store = false">取消</el-button>
                <el-button type="primary" @click="savestore"> 确认 </el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="ywy" title="选择业务员" width="23%">
        <el-select v-model="yy" multiple filterable clearable placeholder="选择业务员">
            <el-option v-for="item in allywy1" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="ywy = false">取消</el-button>
                <el-button type="primary" @click="saveywy"> 确认 </el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="area" title="选择区域" width="16%">
        <el-select v-model="aa" multiple filterable clearable placeholder="选择区域">
            <el-option v-for="item in allarea" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="area = false">取消</el-button>
                <el-button type="primary" @click="savearea"> 确认 </el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible" title="充值" width="30%">
        <el-row style="background-color: #0082fc; border-radius: 10px; color: white">
            <el-col :span="24" style="padding: 15px 26px">账号余额(元)</el-col>
            <el-col :span="24" style="padding: 15px 26px">{{ recharge }}</el-col>
        </el-row>
        <el-collapse v-model="activeNames" style="margin-top: 14px" @change="handleChange">
            <el-collapse-item title="支付宝充值" name="1">
                <el-row style="margin-top: 25px">
                    <el-col :span="3"> 收款码 </el-col>
                    <el-col :span="21">
                        <el-image :zoom-rate="1.2" :preview-src-list="[
                            'https://xuanzj.xianniuzu.com/wx_image/shoukuanma.jpeg',
                        ]" :initial-index="4" fit="cover" src="https://xuanzj.xianniuzu.com/wx_image/shoukuanma.jpeg"
                                  style="width: 100px; height: 100px"></el-image>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 25px">
                    <el-col :span="24">
                        企业对公支付宝账号：chenxuanzhan@dingtalk.com
                    </el-col>
                </el-row>
                <el-row style="margin: 20px 0">
                    <el-col :span="24">充值金额</el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-input-number v-model="alirecharge" :min="0" :precision="2" style="width: 100%" @change="handleChange" />
                    </el-col>
                </el-row>
            </el-collapse-item>
            <el-alert title="转账时请备注公司名称" type="warning" effect="dark" :closable="false" :show-icon="true" center style="
          border-radius: 10px;
          margin: 10px 0;
          color: red;
          background-color: white;
        ">
            </el-alert>
            <el-collapse-item title="银行卡closable" name="2">
                <el-row>
                    <el-col :span="24">企业名称：深圳市炫垚科技有限公司</el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">开户行：深圳福田银座村镇银行观澜支行</el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">账号：651028239500015</el-col>
                </el-row>
                <el-row style="margin: 20px 0">
                    <el-col :span="24">银行卡转账金额</el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-input-number v-model="bankrecharge" :min="0" :precision="2" style="width: 100%" @change="handleChange" />
                    </el-col>
                </el-row>
            </el-collapse-item>
        </el-collapse>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="saverecharge">提交审核</el-button>
                <el-button @click="dialogVisible = false">取消</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="repu" title="选择复购" width="16%">
        <el-select v-model="repurchases" filterable clearable placeholder="选择复购">
            <el-option label="是" :value="1" />
            <el-option label="否" :value="2" />
        </el-select>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="repu = false">取消</el-button>
                <el-button type="primary" @click="saverepu"> 确认 </el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="newolds" title="选择全新/二手" width="16%">
        <el-select v-model="newold" filterable clearable placeholder="选择全新/二手">
            <el-option label="全新" :value="1" />
            <el-option label="二手" :value="2" />
        </el-select>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="newolds = false">取消</el-button>
                <el-button type="primary" @click="savenewold"> 确认 </el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="leasess" title="选择租赁方案" width="16%">
        <el-select v-model="leases" multiple filterable clearable placeholder="选择租赁方案">
            <el-option label="租立享" value="1" />
            <el-option label="随心购" value="2" />
            <el-option label="六期" value="3" />
            <el-option label="租快享" value="4" />
            <el-option label="九期" value="5" />
            <!-- <el-option label="极速租" value="6" /> -->
            <el-option label="零元租" value="7" />
        </el-select>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="leasess = false">取消</el-button>
                <el-button type="primary" @click="savelease"> 确认 </el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="brands" title="选择品牌" width="16%">
        <el-select v-model="brandss" multiple filterable clearable placeholder="选择品牌">
            <el-option v-for="(item, index) in brand" :key="index" :label="item.Brand" :value="item.Brand" />
        </el-select>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="brands = false">取消</el-button>
                <el-button type="primary" @click="savebrand"> 确认 </el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="prices" title="选择价格" width="16%">
        <el-select v-model="pricess" multiple filterable clearable placeholder="选择价格">
            <el-option label="0-1499" :value="1" />
            <el-option label="1500-2999" :value="2" />
            <el-option label="3000-4999" :value="3" />
            <el-option label="5000-7999" :value="4" />
            <el-option label="8000-9999" :value="5" />
            <el-option label="10000+" :value="6" />
        </el-select>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="prices = false">取消</el-button>
                <el-button type="primary" @click="saveprice"> 确认 </el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script>
import { reactive, ref } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import constant from "@/constant";
import Cookies from "js-cookie";
export default {
    data() {
        return {
            storegroupbad: reactive({}),
            storegroupfirst: reactive({}),
            storegroupoverdue: reactive({}),
            historys:[
                {
                    id:1,
                    name:'0单',
                    checked: false
                },
                {
                    id:2,
                    name:'1-19单',
                    checked: false
                },
                {
                    id:3,
                    name:'20-99单',
                    checked: false
                },
                {
                    id: 4,
                    name: '100-199单',
                    checked: false
                },
                {
                    id: 5,
                    name: '200单以上',
                    checked: false
                },
                {
                    id:6,
                    name:'专卖店',
                    checked: false
                }
            ],
            groupbad: reactive({}),
            groupfirst: reactive({}),
            groupedData: reactive({}),
            checked1: ref(true),
            checked5: ref(false),
            onoff: ref('on'),
            leaseFirstRate: [],
            leaseBadRate: [],
            leaseOverdueRate: [],
            newoldFirstRate: [],
            newoldBadRate: [],
            newoldOverdueRate: [],
            brandFirstRate: [],
            brandBadRate: [],
            brandOverdueRate: [],
            areaFirstRate: [],
            areaBadRate: [],
            areaOverdueRate: [],
            ywyFirstRate: [],
            ywyBadRate: [],
            ywyOverdueRate: [],
            storeFirstRate: [],
            storeBadRate: [],
            storeOverdueRate: [],
            firstrate: [],
            badrate: [],
            overduemer: [],
            dayreport: ref('商户'),
            pricess: [],
            prices: false,
            brands: false,
            brand: [],
            brandss: [],
            leasess: false,
            leases: [],
            newold: ref(''),
            newolds: false,
            downloadProgress: 0,
            yuedu: ref(""),
            leiji: ref(""),
            touru: ref(""),
            Investment: ref({}),
            dayData: [],
            monthData: [],
            cityDatas: [{ updateTime: "" }],
            cityId: "",
            checkInfo: [],
            checkResult: [],
            checkInfo2: [],
            checkResult2: [],
            isdetection: ref(false),
            isdetection2: ref(false),
            overduePage: ref(1),
            overduePageSize: ref(10000),
            overdueTotal: "",
            overdue_time: "",
            overduedata: [{ updateTime: "" }],
            everypage: ref(1),
            everypageSize: ref(100),
            everytotal: 0,
            everyday: [{ updateTime: "" }],
            every_day: "",
            tableywy: [
                {
                    updateTime: "",
                },
            ],
            entry_date: ref(""),
            ywyname: ref(""),
            vv: ref([]),
            mers: "",
            yy: ref([]),
            ss: ref([]),
            aa: ref([]),
            allywy1: [],
            ywy: ref(false),
            pageSize: 10000,
            date: ref(""),
            keyword: "",
            page: 1,
            total: 0,
            tableData: [{ updateTime: "" }],
            loading: ref(false),
            months: [],
            merchantname: "",
            activeIndex: ref(""),
            activeName: ref("first"),
            addtime: "",
            recharge: "",
            alirecharge: ref(0),
            bankrecharge: ref(0),
            dialogVisible: ref(false),
            username: "",
            expired: 0,
            tab_name: ref("first"),
            abouttoexpired: 0,
            on: 0,
            info: [],
            merchants: [],
            ismerchant: "",
            mid: "",
            id: "",
            settle: "",
            cashout: "",
            allmerchant: [],
            allstore: [],
            allarea: [],
            storename: ref(""),
            areaname: ref(""),
            merchant: ref(false),
            store: ref(false),
            area: ref(false),
            monthDatas: [{ updateTime: "" }],
            repu: false,
            repurchases: ''
        };
    },
    methods: {
        historytotal(clickedItem){
            console.log(clickedItem)
            this.historys.forEach(item => {
                item.checked = false;
            });
            // 再将当前点击的标签选中状态设为 true
            clickedItem.checked = true;
            let id = clickedItem.id
            this.storeOverdueRate=this.storegroupoverdue[id]
            this.storeBadRate = this.storegroupbad[id]
            this.storeFirstRate = this.storegroupfirst[id]
        },
        onChange1() {
            if (this.checked1 == true) {
                this.checked1 = false
                this.checked5 = false
            } else {
                this.checked1 = true
                this.checked5 = false
            }
            this.ywyOverdueRate = this.groupedData[0]
            this.ywyBadRate = this.groupbad[0]
            this.ywyFirstRate = this.groupfirst[0]
        },
        onChange5() {
            if (this.checked5 == true) {
                this.checked1 = false
                this.checked5 = false
            } else {
                this.checked1 = false
                this.checked5 = true
            }
            this.ywyOverdueRate = this.groupedData[1]
            this.ywyBadRate = this.groupbad[1]
            this.ywyFirstRate = this.groupfirst[1]
        },
        formatterstoretype(row, column, cellValue) {
            switch (cellValue) {
                case 1:
                    return '连锁';
                case 2:
                    return '店铺';
                case 3:
                    return '柜台';
            }
        },
        formatTime(row, column, cellValue) {
            const dateString = cellValue.substring(0, 10)
            const dateObject = new Date(dateString);
            if (!(dateObject instanceof Date)) {
                throw new Error("输入必须是 Date 对象");
            }
            // 获取当前时间
            let currentDate = new Date();
            // 计算年份和月份的差值
            let yearDiff = currentDate.getFullYear() - dateObject.getFullYear();
            let monthDiff = currentDate.getMonth() - dateObject.getMonth();
            // 计算总月数
            let totalMonths = yearDiff * 12 + monthDiff;
            // 如果当前日期的天数小于入职日期的天数，则月数减 1
            if (currentDate.getDate() < dateObject.getDate()) {
                totalMonths--;
            }
            return totalMonths;
        },
        formatStatus(row, column, cellValue) {
            return cellValue === 1 ? '离职' : '在职';
        },
        dayreportclick() {
            if (this.dayreport == '商户') {
                this.daymerchant()
            }
            if (this.dayreport == '门店') {
                this.daystore()
            }
            if (this.dayreport == '业务员') {
                this.dayywy()
            }
            if (this.dayreport == '区域') {
                this.dayarea()
            }
            if (this.dayreport == '品牌') {
                this.daybrand()
            }
            if (this.dayreport == '二手/全新') {
                this.daynewold()
            }
            if (this.dayreport == '租赁方案') {
                this.daylease()
            }
        },
        daylease() {
            this.axios.get(constant.leaseOverdueRate).then((response) => {
                console.log(response.data)
                this.leaseOverdueRate = response.data
            })
            this.axios.get(constant.leaseBadRate).then((response) => {
                this.leaseBadRate = response.data
            })
            this.axios.get(constant.leaseFirstRate).then((response) => {
                this.leaseFirstRate = response.data
            })
        },
        daynewold() {
            this.axios.get(constant.newoldOverdueRate).then((response) => {
                console.log(response.data)
                this.newoldOverdueRate = response.data
            })
            this.axios.get(constant.newoldBadRate).then((response) => {
                this.newoldBadRate = response.data
            })
            this.axios.get(constant.newoldFirstRate).then((response) => {
                this.newoldFirstRate = response.data
            })
        },
        daybrand() {
            this.axios.get(constant.brandOverdueRate).then((response) => {
                console.log(response.data)
                this.brandOverdueRate = response.data
            })
            this.axios.get(constant.brandBadRate).then((response) => {
                this.brandBadRate = response.data
            })
            this.axios.get(constant.brandFirstRate).then((response) => {
                this.brandFirstRate = response.data
            })
        },
        dayarea() {
            this.axios.get(constant.areaOverdueRate).then((response) => {
                console.log(response.data)
                this.areaOverdueRate = response.data
            })
            this.axios.get(constant.areaBadRate).then((response) => {
                this.areaBadRate = response.data
            })
            this.axios.get(constant.areaFirstRate).then((response) => {
                this.areaFirstRate = response.data
            })
        },
        dayywy() {
            this.axios.get(constant.ywyOverdueRate).then((response) => {
                console.log(response.data)
                response.data.forEach(item => {
                    const resValue = item.ywyname.res;
                    // 如果 groupedData 中没有该 res 值对应的数组，则创建一个新数组
                    if (!this.groupedData[resValue]) {
                        this.groupedData[resValue] = [];
                    }
                    // 将当前项添加到对应的数组中
                    this.groupedData[resValue].push(item);
                });
                this.ywyOverdueRate = this.groupedData[0]
            })
            this.axios.get(constant.ywyBadRate).then((response) => {
                response.data.forEach(item => {
                    const resValue = item.ywyname.res;
                    // 如果 groupedData 中没有该 res 值对应的数组，则创建一个新数组
                    if (!this.groupbad[resValue]) {
                        this.groupbad[resValue] = [];
                    }
                    // 将当前项添加到对应的数组中
                    this.groupbad[resValue].push(item);
                });
                this.ywyBadRate = this.groupbad[0]
            })
            this.axios.get(constant.ywyFirstRate).then((response) => {
                response.data.forEach(item => {
                    const resValue = item.ywyname.res;
                    // 如果 groupedData 中没有该 res 值对应的数组，则创建一个新数组
                    if (!this.groupfirst[resValue]) {
                        this.groupfirst[resValue] = [];
                    }
                    // 将当前项添加到对应的数组中
                    this.groupfirst[resValue].push(item);
                });
                this.ywyFirstRate = this.groupfirst[0]
            })
        },
        daystore() {
            this.axios.get(constant.storeOverdueRate).then((response) => {
                console.log(response.data)
                response.data.forEach(item => {
                    const total = item.storename.total;
                    // 如果 groupedData 中没有该 res 值对应的数组，则创建一个新数组
                    if (!this.storegroupoverdue[1]) {
                        this.storegroupoverdue[1] = [];
                    }
                    if (!this.storegroupoverdue[2]) {
                        this.storegroupoverdue[2] = [];
                    }
                    if (!this.storegroupoverdue[3]) {
                        this.storegroupoverdue[3] = [];
                    }
                    if (!this.storegroupoverdue[4]) {
                        this.storegroupoverdue[4] = [];
                    }
                    if (!this.storegroupoverdue[5]) {
                        this.storegroupoverdue[5] = [];
                    }
                    if (!this.storegroupoverdue[6]) {
                        this.storegroupoverdue[6] = [];
                    }
                    // 将当前项添加到对应的数组中
                    if(total == 0){
                        this.storegroupoverdue[1].push(item);
                    }
                    if(total >= 1 && total <= 19){
                        this.storegroupoverdue[2].push(item);
                    }
                    if (total >= 20 && total <= 99) {
                        this.storegroupoverdue[3].push(item);
                    }
                    if (total >= 100 && total <= 199) {
                        this.storegroupoverdue[4].push(item);
                    }
                    if (total >= 200) {
                        this.storegroupoverdue[5].push(item);
                    }
                });
                this.storeOverdueRate = response.data
            })
            this.axios.get(constant.storeBadRate).then((response) => {
                response.data.forEach(item => {
                    const total = item.storename.total;
                    // 如果 groupedData 中没有该 res 值对应的数组，则创建一个新数组
                    if (!this.storegroupbad[1]) {
                        this.storegroupbad[1] = [];
                    }
                    if (!this.storegroupbad[2]) {
                        this.storegroupbad[2] = [];
                    }
                    if (!this.storegroupbad[3]) {
                        this.storegroupbad[3] = [];
                    }
                    if (!this.storegroupbad[4]) {
                        this.storegroupbad[4] = [];
                    }
                    if (!this.storegroupbad[5]) {
                        this.storegroupbad[5] = [];
                    }
                    if (!this.storegroupbad[6]) {
                        this.storegroupbad[6] = [];
                    }
                    // 将当前项添加到对应的数组中
                    if (total == 0) {
                        this.storegroupbad[1].push(item);
                    }
                    if (total >= 1 && total <= 19) {
                        this.storegroupbad[2].push(item);
                    }
                    if (total >= 20 && total <= 99) {
                        this.storegroupbad[3].push(item);
                    }
                    if (total >= 100 && total <= 199) {
                        this.storegroupbad[4].push(item);
                    }
                    if (total >= 200) {
                        this.storegroupbad[5].push(item);
                    }
                });
                this.storeBadRate = response.data
            })
            this.axios.get(constant.storeFirstRate).then((response) => {
                response.data.forEach(item => {
                    const total = item.storename.total;
                    // 如果 groupedData 中没有该 res 值对应的数组，则创建一个新数组
                    if (!this.storegroupfirst[1]) {
                        this.storegroupfirst[1] = [];
                    }
                    if (!this.storegroupfirst[2]) {
                        this.storegroupfirst[2] = [];
                    }
                    if (!this.storegroupfirst[3]) {
                        this.storegroupfirst[3] = [];
                    }
                    if (!this.storegroupfirst[4]) {
                        this.storegroupfirst[4] = [];
                    }
                    if (!this.storegroupfirst[5]) {
                        this.storegroupfirst[5] = [];
                    }
                    if (!this.storegroupfirst[6]) {
                        this.storegroupfirst[6] = [];
                    }
                    // 将当前项添加到对应的数组中
                    if (total == 0) {
                        this.storegroupfirst[1].push(item);
                    }
                    if (total >= 1 && total <= 19) {
                        this.storegroupfirst[2].push(item);
                    }
                    if (total >= 20 && total <= 99) {
                        this.storegroupfirst[3].push(item);
                    }
                    if (total >= 100 && total <= 199) {
                        this.storegroupfirst[4].push(item);
                    }
                    if (total >= 200) {
                        this.storegroupfirst[5].push(item);
                    }
                });
                this.storeFirstRate = response.data
            })
        },
        saveprice() {
            if (this.pricess.length == 0) {
                this.pricess = []
            }
            if (this.activeName == 'second') {
                this.getMonthData(1);
            }
            this.prices = false
        },
        price() {
            this.prices = true
        },
        savebrand() {
            console.log(this.brandss)
            if (this.brandss.length == 0) {
                this.brandss = []
            }
            this.brands = false
            if (this.activeName == 'first') {
                this.getdata(1)
            }
            if (this.activeName == 'second') {
                this.getMonthData(1);
            }
        },
        allbrand() {
            this.brands = true
            this.axios.post(constant.brand).then((response) => {
                console.log(response.data)
                this.brand = response.data
            })
        },
        savelease() {
            console.log(this.leases)
            if (this.leases.length == 0) {
                this.leases = []
            }
            if (this.activeName == 'first') {
                this.getdata(1)
            }
            if (this.activeName == 'second') {
                this.getMonthData(1);
            }
            this.leasess = false
        },
        lease() {
            this.leasess = true
        },
        savenewold() {
            console.log(this.newold)
            if (this.newold == undefined) {
                this.newold = ''
            }
            if (this.activeName == 'first') {
                this.getdata(1)
            }
            if (this.activeName == 'second') {
                this.getMonthData(1);
            }
            this.newolds = false
        },
        neworold() {
            this.newolds = true
        },
        saverepu() {
            this.repu = false
        },
        repurchase() {
            this.repu = true
        },
        async exportall2() {
            this.$message.success("开始导出，请稍后...")
            try {
                const response = await this.axios.post(constant.exportExcel2, {
                    ywy: this.yy,
                    merchant: this.vv,
                    month: this.date,
                    store: this.ss,
                    city: this.aa,
                }, {
                    responseType: 'blob', // 以 blob 形式接收响应数据
                    onDownloadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        console.log(percentCompleted);
                        this.downloadProgress = percentCompleted; // 更新下载进度
                        this.$forceUpdate(); // 强制更新视图
                    },
                });
                // 创建下载链接
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '整体数据.csv'); // 设置下载文件名
                document.body.appendChild(link);
                link.click();
                this.$message.success("下载完成");
                // 清除 URL 对象并重置进度
                this.downloadProgress = 0;
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("下载文件失败:", error);
                this.downloadProgress = 0;
            }
        },
        async exportall() {
            this.$message.success("开始导出，请稍后...")
            try {
                const response = await this.axios.post(constant.exportExcel, {
                    ywy: this.yy,
                    merchant: this.vv,
                    month: this.date,
                    store: this.ss,
                    city: this.aa,
                }, {
                    responseType: 'blob', // 以 blob 形式接收响应数据
                    onDownloadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        console.log(percentCompleted);
                        this.downloadProgress = percentCompleted; // 更新下载进度
                        this.$forceUpdate(); // 强制更新视图
                    },
                });
                // 创建下载链接
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '整体数据.csv'); // 设置下载文件名
                document.body.appendChild(link);
                link.click();
                this.$message.success("下载完成");
                // 清除 URL 对象并重置进度
                this.downloadProgress = 0;
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("下载文件失败:", error);
                this.downloadProgress = 0;
            }
        },
        exportfile() {
            ElMessageBox.prompt('请输入密码下载', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputType: "password"
            })
                .then(({ value }) => {
                    if (value == '578900') {
                        this.exportall()
                    } else {
                        this.$message.error('密码错误');
                    }
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消',
                    })
                })
        },
        Detection() {
            console.log("开始检测");
            this.loading = true;
            this.checkInfo = this.checkResult = [];
            this.axios.get(constant.checkWork).then((response) => {
                console.log(response.data);
                this.checkResult.push(response.data.top);
                this.checkInfo = response.data.bottom;
                this.loading = false;
                this.isdetection = true;
            });
        },
        Detection2() {
            console.log("开始检测");
            this.loading = true;
            this.checkInfo2 = this.checkResult2 = [];
            this.axios.get(constant.checkWork2).then((response) => {
                console.log(response.data);
                this.checkResult2.push(response.data.top);
                this.checkInfo2 = response.data.bottom;
                this.loading = false;
                this.isdetection2 = true;
            });
        },
        allywy() {
            this.ywy = true;
            this.axios.get(constant.ywy).then((response) => {
                console.log(response.data);
                let array = response.data;
                for (let index = 0; index < array.length; index++) {
                    const element = array[index];
                    const status = element.res == 1 ? '离职' : '在职';
                    const label = `${element.ywyname}-${status}-入职时间-${element.addtime}`;
                    console.log(element.ywyname, label); // 打印调试信息
                    this.allywy1.push({ value: element.id, label: label });
                }
            });
        },
        getywy(type) {
            this.loading = true;
            this.axios
                .get(constant.getywydata, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        ywy: this.yy,
                        city: this.aa,
                        store: this.ss,
                        merchant: this.vv,
                        entry_date: this.entry_date,
                        type: type,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.tableywy = response.data.array;
                    this.tableywy.updateTime = response.data.updateTime;
                    this.loading = false;
                });
        },
        getdata(type) {//整体数据
            this.loading = true;
            this.axios
                .get(constant.getdata, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        ywy: type == 2 ? "" : this.yy,
                        merchant: type == 2 ? "" : this.vv,
                        month: type == 2 ? "" : this.date,
                        store: type == 2 ? "" : this.ss,
                        city: type == 2 ? "" : this.aa,
                        type: type,
                        repurchases: type == 2 ? '' : this.repurchases,
                        newold: type == 2 ? '' : this.newold,
                        leases: type == 2 ? '' : this.leases,
                        brandss: type == 2 ? '' : this.brandss
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.merchants = response.data;
                    this.loading = false;
                });
        },
        search() {
            if (this.tab_name == "first") {
                this.getdata(1);
            }
            if (this.tab_name == "three") {
                this.getstores(1);
            }
            if (this.tab_name == "four") {
                this.getywy(1);
            }
            if (this.tab_name == "five") {
                this.everydaydata(1);
            }
            if (this.tab_name == "six") {
                this.overduedatas(1);
            }
            if (this.tab_name == "eight") {
                this.getCityData(1);
            }
        },
        handleSizeChange(val) {
            this.page = 1;
            this.pageSize = val;
            this.getstores(1);
        },
        handleCurrentChange(val) {
            console.log(val);
            this.page = val;
            if (val != 1) {
                this.getstores(1);
            }
        },
        overdueSizeChange(val) {
            this.overduepage = 1;
            this.overduePageSize = val;
            this.overduedatas(1);
        },
        overdueCurrentChange(val) {
            console.log(val);
            this.overduePage = val;
            this.overduedatas(1);
        },
        handleClick(tab) {
            this.tab_name = tab.props.name;
            this.vv = "";
            this.yy = "";
            this.ss = "";
            this.aa = "";
            this.merchantname = "";
            this.storename = "";
            this.ywyname = "";
            this.areaname = "";
            if (tab.props.name == "first") {
                this.activeIndex = "";
                this.date = "";
                this.getdata(0);
            }
            if (tab.props.name == "second") {
                this.getMonthData(0);
            }
            if (tab.props.name == "three") {
                this.tableData = [];
                this.getstores(0);
            }
            if (tab.props.name == "four") {
                this.date = "";
                this.getywy(0);
            }
            if (tab.props.name == "five") {
                this.everydaydata(0);
            }
            if (tab.props.name == "six") {
                this.overduedatas(0);
            }
            if (tab.props.name == "eight") {
                this.getCity();
                this.getCityData(0);
            }
            if (tab.props.name == "nine") {
                this.dayMonthData(0);
            }
            if (tab.props.name == "ten") {
                this.getFundProfile(0);
            }
            if (tab.props.name == "twelve") {
                this.daymerchant();
            }
        },
        daymerchant() {
            this.axios.get(constant.merchant).then((response) => {
                console.log(response.data)
                this.overduemer = response.data
            })
            this.axios.get(constant.badrate).then((response) => {
                this.badrate = response.data
            })
            this.axios.get(constant.firstrate).then((response) => {
                this.firstrate = response.data
            })
        },
        getFundProfile() {
            this.loading = true;
            this.axios
                .get(constant.getFundProfile, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        merchant: this.vv,
                    },
                })
                .then((response) => {
                    this.Investment = response.data;
                    this.touru = response.data.result.touru;
                    this.leiji = response.data.result.leiji;
                    this.yuedu = response.data.result.yuedu;
                    this.loading = false;
                });
        },
        getMonthData(type) {
            this.loading = true;
            this.axios
                .get(constant.getMonthData, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        merchant: this.vv,
                        store: this.ss,
                        ywy: this.yy,
                        city: this.aa,
                        type: type,
                        brandss: this.brandss,
                        newold: this.newold,
                        leases: this.leases,
                        pricess: this.pricess
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    this.loading = false;
                    if ('msg' in response.data) {
                        this.$message.error(response.data.msg)
                        return
                    }
                    this.monthDatas = response.data.data;
                    this.monthDatas.updateTime = response.data.updateTime;
                });
        },
        dayMonthData() {
            this.loading = true;
            this.axios.get(constant.dayMonthData).then((response) => {
                this.dayData = response.data.dayData;
                this.monthData = response.data.monthData;
                this.loading = false;
            });
        },
        getCityData(type) {
            this.loading = true;
            this.axios
                .get(constant.getCityData, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        cityId: this.aa,
                        ywy: this.yy,
                        store: this.ss,
                        merchant: this.vv,
                        type: type,
                    },
                })
                .then((response) => {
                    this.cityDatas = response.data.array;
                    this.cityDatas.updateTime = response.data.updateTime;
                    this.loading = false;
                });
        },
        getCity() {
            this.cityData = [];
            this.axios.get(constant.getCity).then((response) => {
                this.cityData = response.data;
            });
        },
        everySizeChange(val) {
            this.everypage = 1;
            this.everypageSize = val;
            this.everydaydata(1);
        },
        everyCurrentChange(val) {
            this.everypage = val;
            this.everydaydata(1);
        },
        overduedatas(type) {
            this.loading = true;
            this.axios
                .get(constant.overduedatas, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        date: this.overdueTime,
                        pageSize: this.overduePageSize,
                        page: this.overduePage,
                        type: type,
                    },
                })
                .then((response) => {
                    this.overduedata = response.data.result;
                    this.overduedata.updateTime = response.data.updateTime;
                    this.overdueTotal = response.data.total * 1;
                    this.loading = false;
                });
        },
        everydaydata(type) {
            this.loading = true;
            this.axios
                .get(constant.geteveryday, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        date: this.every_day,
                        pageSize: this.everypageSize,
                        page: this.everypage,
                        merchant: this.vv,
                        type: type,
                    },
                })
                .then((response) => {
                    this.everyday = response.data.result;
                    this.everyday.updateTime = response.data.updateTime;
                    this.everytotal = response.data.total * 1;
                    this.loading = false;
                });
        },
        getstores(type) {
            this.loading = true;
            this.axios
                .get(constant.getstores, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        keyword: type == 2 ? "" : this.keyword,
                        date: type == 2 ? "" : this.date,
                        pageSize: type == 2 ? "" : this.pageSize,
                        page: type == 2 ? "" : this.page,
                        type: type,
                    },
                })
                .then((response) => {
                    this.tableData = response.data.array;
                    this.tableData.updateTime = response.data.updateTime;
                    this.total = response.data.total * 1;
                    this.loading = false;
                });
        },
        select() {
            if (this.tab_name == "first") {
                this.getdata(1);
                return false;
            }
            if (this.tab_name == "second") {
                this.getMonthData(1);
                return false;
            }
            if (this.tab_name == "four") {
                this.getywy(1);
                return false;
            }
            if (this.tab_name == "eight") {
                this.getCityData(1);
                return false;
            }
            if (this.tab_name == "ten") {
                this.getFundProfile();
                return false;
            }
            this.$message.error("不支持此筛选");
            this.vv = "";
            this.yy = "";
            this.ss = "";
            this.aa = "";
            this.merchantname = "";
            this.storename = "";
            this.ywyname = "";
            this.areaname = "";
        },
        saveywy() {
            console.log(this.tab_name);
            if (this.loading) {
                ElMessage({
                    message: "请等待数据处理完成后操作！",
                    type: "warning",
                });
                this.ywy = false;
                return false;
            }
            if (this.yy == "") {
                this.ywyname = "";
            } else {
                this.ywyname = 1;
            }
            this.ywy = false;
            this.select();
        },
        savemerchant() {
            console.log(this.vv, this.allmerchant);
            let allmer = [];
            for (let i = 0; i < this.allmerchant.length; i++) {
                allmer[this.allmerchant[i]['value']] = this.allmerchant[i]['label']
            }
            console.log(allmer)
            this.mers = ''
            for (let i = 0; i < this.vv.length; i++) {
                const value = this.vv[i];
                console.log(value)
                this.mers += allmer[value];
                if (i != this.vv.length - 1) {
                    this.mers += ",";
                }
            }
            if (this.loading) {
                ElMessage({
                    message: "请等待数据处理完成后操作！",
                    type: "warning",
                });
                this.merchant = false;
                return false;
            }
            if (this.vv == "") {
                this.merchantname = "";
            } else {
                this.merchantname = 1;
            }
            this.merchant = false;
            this.select();
        },
        savestore() {
            console.log(this.ss, this.activeIndex);
            if (this.loading) {
                ElMessage({
                    message: "请等待数据处理完成后操作！",
                    type: "warning",
                });
                this.store = false;
                return false;
            }
            if (this.ss == "") {
                this.storename = "";
            } else {
                this.storename = 1;
            }
            this.store = false;
            this.select();
        },
        savearea() {
            if (this.loading) {
                ElMessage({
                    message: "请等待数据处理完成后操作！",
                    type: "warning",
                });
                this.area = false;
                return false;
            }
            if (this.aa == "") {
                this.areaname = "";
            } else {
                this.areaname = 1;
            }
            this.area = false;
            this.select();
        },
        allmerchant2() {
            this.merchant = true;
            this.allmerchant = [];
            this.axios.get(constant.allmerchant).then((response) => {
                console.log(response.data);
                for (var i = 0; i < response.data.length; i++) {
                    this.allmerchant.push({
                        value: response.data[i].ID,
                        label: response.data[i].Merchant,
                    });
                }
            });
        },
        allstore2() {
            this.store = true;
            this.allstore = [];
            this.axios.get(constant.allstore).then((response) => {
                console.log(response.data);
                for (var i = 0; i < response.data.length; i++) {
                    this.allstore.push({
                        value: response.data[i].id,
                        label: response.data[i].storename,
                    });
                }
            });
        },
        allarea2() {
            this.area = true;
            this.allarea = [];
            this.axios.get(constant.allarea).then((response) => {
                console.log(response.data);
                for (var i = 0; i < response.data.length; i++) {
                    this.allarea.push({
                        value: response.data[i].id,
                        label: response.data[i].city,
                    });
                }
            });
        },
        rechargedetail() {
            this.$router.push({ path: "/recharge" });
        },
        saverecharge() {
            var title = "";
            if (this.alirecharge != 0) {
                title += "支付宝充值" + this.alirecharge + "元";
            }
            if (this.bankrecharge != 0) {
                title += "银行卡充值" + this.bankrecharge + "元";
            }
            if (title == "") {
                this.$message.error("充值金额不能为0！");
                return false;
            }
            ElMessageBox.confirm("确认" + title + "?", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.axios
                    .get(constant.savenumber, {
                        headers: {
                            "content-type": "application/json",
                        },
                        params: {
                            alirecharge: this.alirecharge,
                            bankrecharge: this.bankrecharge,
                            mid: this.mid,
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        if (response.data.code == 200) {
                            this.$message.success("提交成功");
                            this.dialogVisible = false;
                        } else {
                            this.$message.error("提交失败");
                        }
                    });
            });
        },
        alipay() {
            console.log(this.num, this.mid);
            this.axios
                .get(constant.goalipay, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        total_amount: this.num,
                        mid: this.mid,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                });
        },
        handleChange(value) {
            console.log(value);
        },
        chongzhi() {
            this.dialogVisible = true;
        },
        Settle() {
            this.$router.push({ path: "/settle" });
        },
        Cashout() {
            this.$router.push({ path: "/cashout" });
        },
        GetStatisticInfo() {
            this.axios
                .get(constant.statisticinfo, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    this.info = response.data;
                });
        },
        GetSettleNCashout() {
            this.axios
                .get(constant.sncinfo, {
                    headers: {
                        "content-type": "application/json",
                    },
                    params: {
                        mid: this.mid,
                    },
                })
                .then((response) => {
                    this.cashout = response.data.cashout;
                });
        },
    },
    created: function () {
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.id = Cookies.get("id");
        this.GetSettleNCashout();
        var dd = new Date();
        var year = dd.getFullYear();
        var month =
            dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
        var day = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        this.curdate = year + "-" + month + "-" + day;
        const username = Cookies.get("name");
        this.username = username;
        this.getdata(0);
    },
};
</script>
<style scoped>
.el-space{
    display: flex;
}
.el-space .el-space__item{
    flex: 1;
}
.monthclass tr:first-child {
    background-color: yellow;
    /* 设置背景颜色 */
    font-weight: bold;
    /* 设置字体加粗 */
}
.monthclass tr:nth-child(2) {
    /* 你的样式 */
    background-color: skyblue;
    /* 例如，设置背景颜色为黄色 */
}
.tip {
    border-radius: 100%;
    border: 1px solid;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 15px;
    height: 15px;
}
.el-space__item {
    width: 100%;
}
.el-divider__text {
    background-color: #f5f5f5;
}
.fontcolor {
    color: red;
}
.el-pagination {
    text-align: center;
    margin-top: 20px;
}
.is-always-shadow2 {
    height: 164.5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.el-card__body {
    padding: 10px;
}
.el-menu-demo {
    display: flex;
    overflow-x: scroll;
}
.el-menu--horizontal>.el-menu-item {
    height: 45px;
    line-height: 45px;
}
.el-card {
    --el-card-padding: 10px 0 10px 20px;
}
.el-tabs__nav-wrap {
    padding-left: 20px;
}
.el-tabs__header {
    line-height: 50px;
    background-color: white;
}
.settleblock {
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.contentblock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card-info-title {
    font-weight: 200;
    font-size: 20px;
}
.card-info-no {
    padding: 5px;
    font-size: 36px;
    font-weight: 300;
}
.card-info-date {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.info-block {
    margin-top: 20px;
}
.home .home-title .title-msg {
    font-size: 20px;
    padding-bottom: 2rem;
}
.home .home-title .greetings {
    font-size: 18px;
    line-height: 50px;
    letter-spacing: 0.1em;
}
.home .home-title .weather {
    font-size: 14px;
    color: #999;
    line-height: 24px;
    letter-spacing: 0.1em;
}
.home .home-title .item {
    color: #808695;
    text-align: center;
    padding: 0.5em;
}
.home .home-title .item-text {
    font-weight: 700;
    font-size: 20px;
    color: #000000;
}
.home .home-card .card-text {
    font-size: 12px;
    font-weight: 400;
    color: #494b52;
    letter-spacing: 0.1em;
    padding-top: 0.4em;
}
.home .home-chart .satisfaction {
    padding-top: 0.2em;
    text-align: center;
}
.home .home-chart .satisfaction-number {
    font-weight: 700;
    font-size: 30px;
    color: #515a6e;
    line-height: 120px;
    text-align: center;
}
.home .home-chart .satisfaction-image {
    width: 60px;
    height: 60px;
    text-align: center;
}
.home .home-chart .satisfaction-msg {
    line-height: 35px;
    color: #808695;
    font-size: 14px;
    text-align: center;
}
.home .home-chart .satisfaction-ratio {
    line-height: 120px;
    color: #36c17b;
    font-size: 18px;
    text-align: center;
}
.home .home-chart .sale-item {
    border-radius: 0.2em;
    line-height: 3.2em;
    text-align: center;
    margin: 1em 0;
}
.home .home-chart .sale-item .item-image {
    top: 0.6em;
}
.home .home-chart .sale-item .item-num {
    height: 2.4em;
    line-height: 2.4em;
    color: white;
    font-weight: bold;
    font-size: 20px;
    text-align: right;
    padding-right: 1em;
}
.home {
    padding-bottom: 4em;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
/**
 内边距
 */
.p4em {
    padding-right: 0.4em;
}
/**
 外边距
 */
.m120 {
    margin-top: 1.2em;
}
.mb120 {
    margin-bottom: 1.2em;
}
/**
 颜色
 */
.c-red {
    color: red;
}
.c-coral {
    color: coral;
}
.c-brown {
    color: brown;
}
.c-cyan {
    color: cyan;
}
.c-forestgreen {
    color: forestgreen;
    font-weight: bold;
}
.c-blue {
    color: #1c86ee;
}
/**
 数字效果
 */
.num-effect {
    color: #494b52;
    font-size: 1.5em;
    font-weight: bold;
}
.f-weight {
    font-weight: bold;
}
.div-right {
    float: right;
}
.icon {
    width: 30px;
    height: 30px;
    right: 0.2em;
}
</style>
